
import { defineComponent } from "vue-demi";
import { VideoIcon } from '@/plugins/icons';
import { copyLink } from "@/utils/global";
import { useAPI } from "@/use";
import { dynamicsObject } from "@/interfaces";
import { commonCheckInputs } from "@/store/common/default/inputs";
import CommonInputs from '@/components/common/inputs/index.vue';

export default defineComponent({
  name: 'DetailsPayment',
  props: {
    payment: {
      type: Object,
      required: true
    } 
  },
  methods: {
    modal() {
      this.$store.commit('createModal', { id: 'instruction', data: { payment: this.payment.id } })
    },
    copyLink(src: string) {
      copyLink(src);
      this.$store.commit('createGeneralNotification', {
        message: 'Ссылка скопирована в буфер обмена!',
        type: 'static',
      })
    },
    async submit() {
      const check = commonCheckInputs(this.payment.data.inputs, this.payment.data.data);
      if (!check.status) return;

      const options = {} as dynamicsObject;
      options[this.payment.id] = this.payment.data.data;

      const result = await useAPI().user.updatePaymentMethod(options);
      this.$store.commit('updatePaymentDetails', result.data);
    }
  },
  components: {
    VideoIcon,
    CommonInputs
  }
})
