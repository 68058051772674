export const MONTHS = [
  { id: 'Jan', name: 'Январь', count: 1 },
  { id: 'Feb', name: 'Февраль', count: 2 },
  { id: 'Mar', name: 'Март', count: 3 },
  { id: 'Apr', name: 'Апрель', count: 4 },
  { id: 'May', name: 'Май', count: 5 },
  { id: 'Jun', name: 'Июнь', count: 6 },
  { id: 'Jul', name: 'Июль', count: 7 },
  { id: 'Aug', name: 'Август', count: 8 },
  { id: 'Sep', name: 'Сентябрь', count: 9 },
  { id: 'Oct', name: 'Октябрь', count: 10 },
  { id: 'Nov', name: 'Ноябрь', count: 11 },
  { id: 'Dec', name: 'Декабрь', count: 12 },
]

export enum MONTH_TYPES {
  'Jan' = 'Jan',
  'Feb' = 'Feb',
  'Mar' = 'Mar',
  'Apr' = 'Apr',
  'May' = 'May',
  'Jun' = 'Jun',
  'Jul' = 'Jul',
  'Aug' = 'Aug',
  'Sep' = 'Sep',
  'Oct' = 'Oct',
  'Nov' = 'Nov',
  'Dec' = 'Dec',
}

export const getTargetPosition = (target: JQuery<HTMLElement>, width?: number, right = false) => {
  const top = Number(target.offset()?.top) + Number(target.innerHeight()) + 4;
  const left = Number(target?.offset()?.left) - (width && right ? (width - (target.width() || 0)) : 0);
  return { top, left };
}

export enum MONTHS_NAMES { 'январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь' }
export enum MONTHS_NAMES_CIR { 'янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек' }
export enum MONTHS_NAMES_CASE { 'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря' }
export enum DAYS_NAMES { 'воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота' }