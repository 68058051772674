
import { defineComponent } from "vue-demi";
import { VideoIcon } from '@/plugins/icons';
import { useAPI } from "@/use";
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "DetailsPaymentBotpay",
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  methods: {
    modal() {
      this.$store.commit("createModal", {
        id: "instruction",
        data: { payment: this.payment.id },
      });
    },
    async addCard() {
      this.pending = true;
      await useAPI().payment.botpay.addCustomerMethod();
      location.reload();
      // window.open(result.data.link);
    },
    async removeCard() {
      this.pending = true;
      await useAPI().payment.botpay.removeCardMethod();
      this.payment.data = null;
      this.pending = false;
      this.$store.commit("clearBotpayPayment");
    },
    // Emitter
    onEmits() {
      this.emitter.on("botpay-card-billing", this.botpayCardBillingEmitter);
    },
    offEmits() {
      this.emitter.off("botpay-card-billing", this.botpayCardBillingEmitter);
    },
    botpayCardBillingEmitter(data: dynamicsObject) {
      this.payment.data = data.botpay;
      this.pending = false;
    },
  },
  components: {
    VideoIcon,
    // VisaIcon,
  }
})
