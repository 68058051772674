
import { defineComponent } from "vue-demi";

export default defineComponent({
  emits: ['selectPayment'],
  name: "DetailsList",
  props: {
    details: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      
    };
  },
});
