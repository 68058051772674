import { dynamicsObject } from "@/interfaces";

export const globalLastElement = (array: Array<dynamicsObject | string | number> | string) => {
  return array[array.length - 1];
}

export const maxHeight = (element: HTMLElement, dec: number) => {
  const height = (document.querySelector('body')?.clientHeight || 975) - dec;
  element.style.maxHeight = `${height}px`;
}

// export const globalOnResizeEvent = (html: string, id: string) => {
//   const element = document.querySelector(html) as HTMLElement;
//   const dec = globalGetResizeHeight(id);
//   maxHeight(element, dec);
//   window.addEventListener('resize', () => { maxHeight(element, dec) });
// }

// export const globalOffResizeEvent = (html: string, id: string) => {
//   const element = document.querySelector(html) as HTMLElement;
//   const dec = globalGetResizeHeight(id);
//   window.removeEventListener('resize', () => { maxHeight(element, dec) });
// }

// export const globalGetResizeHeight = (id: string) => {
//   return [
//     { id: 'director', value: 200 },
//     { id: 'profile-company', value: 315 },
//     { id: 'profile-company-position', value: 251 },
//   ].find(height => height.id === id)?.value || 320;
// }

export const copyLink = (copy: string) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(copy);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = copy;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res(copy) : rej();
      textArea.remove();
    });
  }
}