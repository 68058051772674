import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b7a421e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "details flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list = _resolveComponent("list")!
  const _component_payment_botpay = _resolveComponent("payment-botpay")!
  const _component_payment = _resolveComponent("payment")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_list, {
      details: _ctx.details,
      selected: _ctx.selected,
      onSelectPayment: _ctx.selectPayment
    }, null, 8, ["details", "selected", "onSelectPayment"]),
    (_ctx.selected === 'botpay')
      ? (_openBlock(), _createBlock(_component_payment_botpay, {
          key: 0,
          payment: _ctx.currentPayment
        }, null, 8, ["payment"]))
      : (_openBlock(), _createBlock(_component_payment, {
          key: 1,
          payment: _ctx.currentPayment
        }, null, 8, ["payment"]))
  ]))
}