
import { defineComponent } from "vue-demi";
import Payment from "@/components/payment/details/index.vue";

export default defineComponent({
  name: "PaymentDetailsView",
  components: {
    Payment,
  },
});
