import { MONTHS_NAMES, MONTHS_NAMES_CIR } from '@/store/common/default/dates';
import zero from './zero.filter';

export default (value: Date | string | number, filter = 'date', options?: string): string => {
  if (!value) return '';

  if (filter === 'monthYear') return monthYearType(value as Date);
  if (filter === 'monthYearAndTime') return monthYearTimeType(new Date(value));
  
  const date = new Date(value);
  const result = [];

  const day = zero(date.getDate());
  const month = filter.includes('MonthName') ? MONTHS_NAMES_CIR[date.getMonth()] : zero(date.getMonth() + 1);
  const year = date.getFullYear();
  const minute = zero(date.getHours());
  const second = zero(date.getMinutes());

  if (filter.includes('text')) result.push(currentDay(date) || `${day}.${month}.${year} в`);
  if (filter.includes('date') && filter.includes('MonthName')) result.push(`${day} ${month}`);
  else if (filter.includes('date') && filter.includes('MonthOnly')) result.push(`${day}.${month}`);
  else if (filter.includes('date') && options) result.push(`${day} ${month} ${year}`);
  else if (filter.includes('date') && !options) result.push(`${day}.${month}.${year}`);
  if (filter.includes('time')) result.push(`${minute}:${second}`);
  return result.join(' ');
};

function currentDay(date: Date) {
  const currentDate = new Date();
  if (currentDate.getDate() - date.getDate() === 0) return 'сегодня в ';
  const yestarday = new Date(Number(new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`)) - 86400000);
  if (yestarday.getTime() - date.getTime() <= 0) return 'вчера в';
  return '';
}

function monthYearType(value: Date) {
  return `${MONTHS_NAMES[value.getMonth()]} ${value.getFullYear()}`;
}

function monthYearTimeType(value: Date) {
  return `${zero(value.getDate())} ${MONTHS_NAMES_CIR[value.getMonth()]} ${value.getFullYear()} ${zero(value.getHours())}:${zero(value.getMinutes())}`;
}