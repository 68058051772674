
import { defineComponent } from "vue-demi";
import { PAYMENT_DETAILS } from "@/store/common/user";
import { useAPI } from "@/use";
import List from './List.vue';
import Payment from './payment/index.vue';
import PaymentBotpay from './payment/Botpay.vue';

export default defineComponent({
  name: "PaymentDetails",
  props: ['empty'],
  data() {
    return {
      selected: 'yoomoney',
      details: PAYMENT_DETAILS,
    }
  },
  created() {
    this.getMethod();
    if (this.$route.query.payment) this.selected = this.$route.query.payment as string;
  },
  methods: {
    async getMethod() {
      const result = await useAPI().user.getPaymentMethod();
      for (const detail of this.details)
        if (detail.inputs)
          detail.data = detail.inputs(result.data[detail.id]);
        else
          detail.data = result.data[detail.id]
        
    },
    selectPayment(payment: string) {
      this.selected = payment;
    },
    router() {
      this.selected = 'yoomoney';
      this.$router.push('/payment/details');
    }
  },
  computed: {
    currentPayment() {
      const detail = this.details.find(d => d.id === this.selected);
      if (!detail) return this.router();
      return detail;
    }
  },
  components: {
    List,
    Payment,
    PaymentBotpay
  }
});
